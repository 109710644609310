<template>
  <div>
    <b-row>
      <b-col md="3">
        <statistic-card-vertical
          color="secondary"
          icon="FlagIcon"
          :statistic="$staticParams.formatCurrency(reportData.total_stock_price)+' TL'"
          statistic-title="Tüm Mevcut Stokların Toplam Değeri"
        />
      </b-col>
      <b-col
        v-if="reportData"
        md="12"
      >

        <b-row>

          <b-col md="12">
            <category-stock-record-table
              :filter-options="filterOptions"
            />
          </b-col>
          <b-col md="12">
            <product-stock-record-table
              :filter-options="filterOptions"
            />
          </b-col>

        </b-row>
      </b-col>

    </b-row>
    <div />
  </div></template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import reportsStoreModule from '../reportsStoreModule'
import categoryStockRecordTable from './CategoryStockRecordTable.vue'
import productStockRecordTable from './ProductStockRecordTable.vue'

export default {
  components: {
    BRow,
    BCol,

    productStockRecordTable,

    categoryStockRecordTable,
    StatisticCardVertical,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      filterData: {
        seller_codes: [0],
        start_year: 2015,
        start_month: 0,
        end_year: 2015,
        end_month: 0,
        type: '',
      },
      reportData: null,

    }
  },
  computed: {
    filterOptions() {
      return {
        start_year: this.filterData.start_year,
        start_month: this.filterData.start_month,
        end_year: this.filterData.end_year,
        end_month: this.filterData.end_month,
        seller_code: '',
        location: '',
        buyer_name: '',
        product_code: '',

      }
    },
    targetMonts() {
      const arr = [{ number: 0, name: 'Tümü' }]
      this.$staticParams.monthNames.forEach(val => {
        arr.push(val)
      })
      return arr
    },

  },

  created() {
    const d = new Date()
    const thisYear = d.getFullYear()

    this.filterData.start_year = thisYear
    this.filterData.end_year = thisYear
    this.filterData.start_month = 11
    this.filterData.end_month = 11
    if (this.$route.query.month) {
      // eslint-disable-next-line radix
      this.filterData.target_month = parseInt(this.$route.query.month)
      // eslint-disable-next-line radix
      this.targetDetailMonth = parseInt(this.$route.query.month)
    }
    if (this.$route.query.year) {
      // eslint-disable-next-line radix
      this.filterData.start_year = parseInt(this.$route.query.year)
      this.targetDetailMonth = 0
    }
    if (this.$route.query.type) {
      if (this.$route.query.type === 'current') {
        this.targetDetailMonth = 13
      }
    } else {
      this.filterData.type = ''
    }
    this.fetchData()
  },

  methods: {
    showSuccessMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarılı'), {
        title: this.$t('İşlem Başarılı'),
        variant: 'success',
        solid: false,
      })
    },
    showErrorMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarısız'), {
        title: this.$t('İşlem Başarısız'),
        variant: 'danger',
        solid: false,
      })
    },
    fetchData() {
      store
        .dispatch('app-reports/fetchStockReportNonDate', this.filterData)
        .then(response => {
          this.reportData = response.data.data
        })
        .catch(() => {
        })
    },
    calculateData() {
      store
        .dispatch('app-reports/calculateReports', this.filterData)
        .then(() => {
          this.fetchData()
          this.showSuccessMessage()
        })
        .catch(() => {
        })
    },
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-reports'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, reportsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    // eslint-disable-next-line no-unused-vars
    const targetsData = ref(null)
    const targetYear = ref(2015)
    const targetYearStart = ref(2015)
    const targetYears = ref([])
    const d = new Date()
    const thisYear = d.getFullYear()

    targetYear.value = thisYear
    const arrTarget = []
    // eslint-disable-next-line no-plusplus
    for (let i = targetYearStart.value; i <= thisYear + 1; i++) {
      arrTarget.push({ text: i, value: i })
    }
    targetYears.value = arrTarget

    const usersData = ref(null)
    store
      .dispatch('app/fetchReportUsers', {
        list_type: 'all',

      })
      .then(response => {
        const { data } = response.data
        const arr = []
        data.forEach(val => {
          arr.push({ label: `${val.seller_name} `, value: val.seller_code })
        })
        arr.unshift({ label: 'Tümü', value: 0 })
        usersData.value = arr
      })
      .catch(() => {
      })

    return {

      // Filter
      avatarText,
      usersData,
      targetYears,
      // UI
      // Extra Filters
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.vgt-table {
    font-size: 13px;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
